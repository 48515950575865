import React from "react";
import abImage from "../../assets/img/ab_img.webp";

function About() {
  return (
    <section id="uber-uns">
      <div className="padding-block-700 padding-inline">
        <div className="even-areas">
          <div className="ab-info-wrapper">
            <div className="ab-header">Über unsere Praxis</div>
            <div className="ab-text">
            Willkommen in der Praxis Retter, ihrer erstklassigen Anlaufstelle für Allgemeinmedizin
            in Berlin Mitte. Unser Team erfahrener Fachärzte ist engagiert ihre Gesundheit an 
            erste Stelle zu setzen. In modernen Praxisräumen bieten wir umfassende allgemeinmedizinische 
            Betreuung, von präventiven Untersuchungen bis zur gezielten Therapie. Mit einem 
            patientenzentrierten Ansatz nehmen wir uns Zeit für individuelle Beratung und legen Wert auf 
            eine vertrauensvolle Arzt-Patienten-Beziehung. Entdecken Sie bei Uns die Verbindung 
            von fachlicher Expertise und persönlicher Fürsorge für Ihre Gesundheit.
            </div>
          </div>
          <div className="ab-img-wrapper">
            <img className="ab-img" src={abImage} alt="aboutImg"></img>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
