import React from "react";
import { useMediaQuery } from "react-responsive";
import MobileTeamSlider from "../../components/TeamSlider/MobileTeamSlider";
import DesktopTeamSlider from "../../components/TeamSlider/DesktopTeamSlider";
import personalData from "../../data/personal.json";

function Team() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  return (
    <section id="team">
      <div className="padding-block-700 padding-inline">
        <div className="team-header">Unser Team</div>
        <div className="team-slider">
          {isMobile ? (
            <MobileTeamSlider
              Items={personalData}
              maxItems={personalData.length}
            />
          ) : (
            <DesktopTeamSlider
              Items={personalData}
              maxItems={personalData.length}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default Team;
