import React from "react";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import dataServices from "../../data/services.json";
import MobileServiceSlider from "../../components/ImgGallery/MobileImgGallery";
import DesktopServiceSlider from "../../components/ImgGallery/DesktopImgGallery";

function Services() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });
  return (
    <section id="leistungen">
      <div className="padding-block-700 padding-inline">
        <div className="service-welcome-text">
          <p className="service-welcome-text-header">
            Willkommen auf unserer Webseite
          </p>
          <p>
            Liebe Besucher, auf der Internetseite unserer allgemeinmedizinischen
            Praxis möchten wir Ihnen unser Team und unser Leistungsspektrum
            vorstellen.
          </p>
        </div>

        <div className="service-header">Unser Leistungsspektrum</div>
        {isMobile ? (
          <MobileServiceSlider
            Items={dataServices}
            maxItems={dataServices.length}
          />
        ) : (
          <DesktopServiceSlider
            Items={dataServices}
            maxItems={dataServices.length}
          />
        )}

        <HashLink to={"/#kontakt"} className="btn">
          Jetzt Termin buchen
        </HashLink>
      </div>
    </section>
  );
}

export default Services;
