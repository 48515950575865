import React, { useEffect, useState } from "react";
import hrMobileBackgroundImg from "../../assets/img/hero_mobile_bg.webp";
import hrDesktopBackgroundImg from "../../assets/img/hero_bg_1_blackend.webp";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import officeTimes from "../../data/officeTimes.json";

function Hero() {
  const [currentOfficeTime, setCurrentOfficeTime] = useState("");
  var dayIndexArray = { Mo: 0, Di: 1, Mi: 2, Do: 3, Fr: 4, Sa: 5, So: 5 };
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  useEffect(() => {
    var options = {
      weekday: "short",
      timeZone: "Europe/Berlin",
    };
    var currentWeekDay = new Date()
      .toLocaleDateString("de", options)
      .replace(".", "");

    var dayIndex = dayIndexArray[currentWeekDay];

    var _currentOfficeTime = officeTimes[dayIndex][currentWeekDay];

    if (_currentOfficeTime) {
      setCurrentOfficeTime(_currentOfficeTime);
    } else {
      setCurrentOfficeTime("Geschlossen");
    }
  }, [officeTimes]);

  return (
    <section id="home">
      <div className="hr-wrapper">
        <div className="even-areas">
          {isMobile ? (
            <div className="hr-heading">
              <div className="hr-header">Hausarztpraxis</div>
              <div className="hr-sub-header">Jens-Uwe Retter</div>
              <div className="hr-tert-header">IHR HAUSARZT IN BERLIN MITTE</div>
            </div>
          ) : (
            <div className="hr-heading">
              <div className="hr-header">Hausarztpraxis</div>
              <div className="hr-sub-header">Jens-Uwe Retter</div>
              <div className="hr-tert-header">IHR HAUSARZT IN BERLIN MITTE</div>
              <div className="hr-info-card">
                <div className="hr-info-header">Heutige Sprechzeiten:</div>
                <div className="hr-info-time">{currentOfficeTime}</div>
                <HashLink
                  to={"/#sprechzeiten"}
                  className="btn"
                  data-type="inverted"
                >
                  Weitere Informationen
                </HashLink>
              </div>
            </div>
          )}

          <img
            className="hr-background-img"
            alt="background-img"
            src={isMobile ? hrMobileBackgroundImg : hrDesktopBackgroundImg}
          ></img>
        </div>

        {isMobile ? (
          <div className="hr-info-card">
            <div className="hr-info-header">Heutige Sprechzeiten:</div>
            <div className="hr-info-time">{currentOfficeTime}</div>
            <HashLink
              to={"/#sprechzeiten"}
              className="btn"
              data-type="inverted"
            >
              Weitere Informationen
            </HashLink>
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
}

export default Hero;
