import React, { useEffect, useState, useCallback, useRef } from "react";

function MobileImgGallery({ Items, maxItems }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeAutoplay, setActiveAutoplay] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const autoplayRef = useRef();

  const settings = {
    maxItems: maxItems,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const goTo = useCallback(
    (index) => {
      if (!isAnimating) {
        setCurrentIndex(index);
        setIsAnimating(true);

        setTimeout(() => {
          setIsAnimating(false);
        }, settings.speed);
      }
    },
    [isAnimating, currentIndex]
  );

  /* Go Next */
  const goNext = () => {
    goTo(currentIndex >= settings.maxItems - 1 ? 0 : currentIndex + 1);
  };

  /* Go Prev */
  const goPrev = () => {
    goTo(currentIndex <= 0 ? settings.maxItems - 1 : currentIndex - 1);
  };

  /* Play Timer */
  const playTimer = () => {
    setActiveAutoplay(true);
  };

  /* Pause Timer */
  const pauseTimer = () => {
    setActiveAutoplay(false);
  };

  useEffect(() => {
    if (settings.autoplay && activeAutoplay) {
      clearTimeout(autoplayRef.current);
      autoplayRef.current = setTimeout(() => {
        goNext();
      }, settings.autoplaySpeed);
    }
  }, [currentIndex, activeAutoplay, isAnimating]);

  const dots = () => {
    const totalItems = Array.apply(null, Array(settings.maxItems));
    const dotsButtons = totalItems.map((item, index) => {
      return (
        <div
          className={
            index === currentIndex
              ? "service-card-dot service-card-dot-active"
              : "service-card-dot"
          }
          key={index.toString()}
          onMouseEnter={pauseTimer}
          onMouseLeave={playTimer}
          onClick={() => goTo(index)}
        ></div>
      );
    });
    return (
      <div className="service-card-list-control">
        <div className="service-card-dots">{dotsButtons}</div>
      </div>
    );
  };

  const slide = (index) => {
    var currentItem = Items[index];
    var activeImg = require("../../assets/img/" + currentItem["img_active"]);

    return (
      <div className="service-card">
        <div className="service-card-img-wrapper service-card-img-wrapper-active">
          <img
            className="service-card-img"
            src={activeImg}
            alt="service-card-img"
          ></img>
        </div>
        <div className="service-card-header">{currentItem["title"]}</div>
      </div>
    );
  };

  return (
    <div className="service-card-slider">
      {slide(currentIndex)}
      {dots()}
    </div>
  );
}

export default MobileImgGallery;
