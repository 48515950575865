import React, { useEffect, useState, useCallback, useRef } from "react";
import chevronLeft from "../../assets/img/chevron-left.webp";
import chevronRight from "../../assets/img/chevron-right.webp";

function MobileTeamSlider({ Items, maxItems }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeAutoplay, setActiveAutoplay] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const autoplayRef = useRef();

  const settings = {
    maxItems: maxItems,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const goTo = useCallback(
    (index) => {
      if (!isAnimating) {
        setCurrentIndex(index);
        setIsAnimating(true);

        setTimeout(() => {
          setIsAnimating(false);
        }, settings.speed);
      }
    },
    [isAnimating, currentIndex]
  );

  /* Go Next */
  const goNext = () => {
    goTo(currentIndex >= settings.maxItems - 1 ? 0 : currentIndex + 1);
  };

  /* Go Prev */
  const goPrev = () => {
    goTo(currentIndex <= 0 ? settings.maxItems - 1 : currentIndex - 1);
  };

  /* Play Timer */
  const playTimer = () => {
    setActiveAutoplay(true);
  };

  /* Pause Timer */
  const pauseTimer = () => {
    setActiveAutoplay(false);
  };

  useEffect(() => {
    if (settings.autoplay && activeAutoplay) {
      clearTimeout(autoplayRef.current);
      autoplayRef.current = setTimeout(() => {
        goNext();
      }, settings.autoplaySpeed);
    }
  }, [currentIndex, activeAutoplay, isAnimating]);

  const dots = () => {
    const totalItems = Array.apply(null, Array(settings.maxItems));
    const dotsButtons = totalItems.map((item, index) => {
      return (
        <div
          className={
            index === currentIndex
              ? "team-slider-dot team-slider-dot-active"
              : "team-slider-dot"
          }
          key={index.toString()}
          onMouseEnter={pauseTimer}
          onMouseLeave={playTimer}
          onClick={() => goTo(index)}
        ></div>
      );
    });
    return <div className="team-slider-pagination">{dotsButtons}</div>;
  };

  const slide = (index) => {
    var currentItem = Items[index];
    var currentImg = require("../../assets/img/" +
      currentItem["personalImage"]);

    return (
      <>
        <div className="even-areas">
          <div className="team-slider-info">
            <div className="team-slider-info-panel">
              <div className="team-slider-info-wrapper">
                <div className="team-slider-info-name-wrapper">
                  <div className="team-slider-info-name">
                    {currentItem["firstName"] + " " + currentItem["lastName"]}
                  </div>
                </div>
                <div className="team-slider-info-job">{currentItem["job"]}</div>
              </div>
              {dots()}
            </div>
          </div>
          <div className="team-slider-img">
            <img alt="team-slider-img" src={currentImg}></img>
          </div>
        </div>
        <div className="team-slider-control">
          <div
            className="team-slider-chevron-wrapper"
            onClick={goPrev}
            datatype="left"
          >
            <img alt="control-left" src={chevronLeft}></img>
          </div>
          <div className="team-slider-chevron-wrapper" onClick={goNext}>
            <img alt="control-right" src={chevronRight}></img>
          </div>
        </div>
      </>
    );
  };

  return <>{slide(currentIndex)}</>;
}

export default MobileTeamSlider;
