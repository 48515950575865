import React from "react";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";
import burgerMenu from "../assets/img/burgerMenu.webp";
import logo from "../assets/img/logo.webp";
import clock from "../assets/img/Clock_Icon.webp";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const openMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const onNavLinkClicked = () => {
    setShowMobileMenu(false);
  };

  const MobileMenu = () => {
    if (showMobileMenu) {
      return (
        <div className="nav-mobile-links">
          <HashLink className="nav-link" to="/" onClick={onNavLinkClicked}>
            Start
          </HashLink>

          <HashLink
            className="nav-link"
            to="/#leistungen"
            onClick={onNavLinkClicked}
          >
            Leistungen
          </HashLink>

          <HashLink
            className="nav-link"
            to="/#uber-uns"
            onClick={onNavLinkClicked}
          >
            <span>Über</span>
          </HashLink>

          <HashLink className="nav-link" to="/#team" onClick={onNavLinkClicked}>
            <span>Team</span>
          </HashLink>

          <HashLink
            className="nav-link"
            to="/#kontakt"
            onClick={onNavLinkClicked}
          >
            <span>Kontakt</span>
          </HashLink>
        </div>
      );
    }
    return;
  };

  return (
    <header className="primary-header">
      <div className="upper-nav">
        <div className="upper-nav-header">
          <img src={clock} alt="uhr"></img>
          <div className="upper-nav-header-text">Akutsprechzeiten:</div>
        </div>
        <div className="upper-nav-content">
          <div className="upper-nav-content-header">Dienstag bis Freitag</div>
          <div className="upper-nav-content-time">9:00 - 10:30 Uhr</div>
        </div>
      </div>
      <div className="nav">
        <HashLink to="/#top">
          <img className="nav-logo" src={logo} alt="logo" />
        </HashLink>
        <div className="nav-links">
          <div className="nav-extended-links">
            <HashLink className="nav-link" to="/#home">
              <span className="btn-icon-hl"></span>
              <span>Start</span>
            </HashLink>

            <HashLink className="nav-link" to="/#leistungen">
              <span className="btn-icon-hl"></span>
              <span>Leistungen</span>
            </HashLink>

            <HashLink className="nav-link" to="/#uber-uns">
              <span className="btn-icon-hl"></span>
              <span>Über</span>
            </HashLink>

            <HashLink className="nav-link" to="/#team">
              <span className="btn-icon-hl"></span>
              <span>Team</span>
            </HashLink>

            <HashLink className="nav-link" data-type="btn" to="/#kontakt">
              <span>Kontakt</span>
            </HashLink>
          </div>
          <div className="nav-mobile">
            <img src={burgerMenu} alt="navMenu" onClick={openMobileMenu} />
          </div>
        </div>
      </div>
      <MobileMenu />
    </header>
  );
}

export default Navbar;
