import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import telIcon from "../../assets/img/contact_phone_img.webp";
import faxIcon from "../../assets/img/684832 1.webp";
import mailIcon from "../../assets/img/mail_icon.webp";
import locIcon from "../../assets/img/location-pin-svgrepo-com 3.webp";
import { useMediaQuery } from "react-responsive";
import praxisInfoPdf from "../../assets/pdf/Praxisinformationen.pdf";

function Contact() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });
  const [formSubmited, setFormSubmited] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    mail: "",
    tel: "",
    subject: "",
    message: "",
  });

  const onSubmitForm = (e) => {
    e.preventDefault();
    sendSuggMail();
  };

  const sendSuggMail = async () => {
    var data = {
      fullName: formData.fullName,
      mail: formData.mail,
      tel: formData.tel,
      subject: formData.subject,
      message: formData.message,
    };

    axios.post("server/sendContactMail.php", data).then(function (response) {
      AfterSubmitAction(true);
    });
  };

  const AfterSubmitAction = (state) => {
    setFormSubmited(state);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const AfterSubmitText = () => {
    if (formSubmited) {
      return (
        <div className="form-submit-message">
          <p>Herzlichen Dank für ihre Kontaktanfrage.</p>
          <p>
            Hiermit bestätigen wir die Ankunft dieser über das Kontaktformular.
            Wir werden Sie so schnell wie möglich über die von Ihnen angegebene
            E-Mail kontaktieren.
          </p>
        </div>
      );
    }
    return <></>;
  };

  const ContactForm = () => {
    if (formSubmited) {
      return <></>;
    }
    return (
      <form data-type="contact" onSubmit={onSubmitForm}>
        <div className="form-control">
          <label htmlFor="fullName" className="form-label">
            * Name
          </label>
          <input
            className="form-input"
            onChange={handleChange}
            type="text"
            name="fullName"
            required
          />
        </div>

        <div className="form-control">
          <label htmlFor="mail" className="form-label">
            * E-Mail
          </label>
          <input
            className="form-input"
            onChange={handleChange}
            type="mail"
            name="mail"
            required
          />
        </div>

        <div className="form-control">
          <label htmlFor="tel" className="form-label">
            * Telefon
          </label>
          <input
            className="form-input"
            onChange={handleChange}
            type="tel"
            name="tel"
            required
          />
        </div>

        <div className="form-control">
          <label htmlFor="subject" className="form-label">
            * Betreff
          </label>
          <input
            className="form-input"
            onChange={handleChange}
            type="text"
            name="subject"
            required
          />
        </div>

        <div className="form-control">
          <label htmlFor="message" className="form-label">
            * Nachricht
          </label>
          <textarea
            className="form-input"
            onChange={handleChange}
            name="message"
            required
          />
        </div>

        <div className="form-control">
          <span className="form-control-info">
            <input
              type="checkbox"
              placeholder="Datenschutz"
              required
              name="datenschutz"
            />
            <span>
              Ich willige in die in der&nbsp;
              <HashLink
                to="/datenschutz"
                target="_blank"
                rel="noreferrer noopener"
              >
                Datenschutzerklärung
              </HashLink>{" "}
              beschriebene Verarbeitung meiner Daten ein:<span>*</span>
            </span>
          </span>
        </div>

        <button type="submit" className="btn">
          Absenden
        </button>
      </form>
    );
  };

  return (
    <section id="kontakt">
      <div className="padding-block-700 padding-inline">
        <div className="even-areas">
          <div className="contact-info">
            <div className="contact-heading">
              <div className="contact-sub-header">
                Haben sie weitere Fragen?
              </div>
              <div className="contact-header">Kontaktieren Sie uns</div>
            </div>
            <div className="contact-info-panel-wrapper">
              {isMobile ? (
                <div className="contact-info-panel">
                  <a href="tel:030 20619680">
                    <img
                      className="contact-info-panel-icon"
                      src={telIcon}
                      alt="info-icon-tel"
                    ></img>
                  </a>
                  <div className="contact-info-panel-text">030 20619680</div>
                </div>
              ) : (
                <div className="contact-info-panel">
                  <img
                    className="contact-info-panel-icon"
                    src={telIcon}
                    alt="info-icon-tel"
                  ></img>
                  <div className="contact-info-panel-text">030 20619680</div>
                </div>
              )}
              <div className="contact-info-panel">
                <img
                  className="contact-info-panel-icon"
                  src={faxIcon}
                  alt="info-icon-fax"
                ></img>
                <div className="contact-info-panel-text">030 2010232</div>
              </div>
              <div className="contact-info-panel">
                <a href="mailto:info@praxis-retter.de">
                  <img
                    className="contact-info-panel-icon"
                    src={mailIcon}
                    alt="info-icon-mail"
                  ></img>
                </a>
                <div className="contact-info-panel-text">
                  info@praxis-retter.de
                </div>
              </div>
              <div className="contact-info-panel">
                <a
                  href="https://maps.app.goo.gl/jHaQZnViXGjAQgfy7"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="contact-info-panel-icon"
                    src={locIcon}
                    alt="info-icon-loc"
                  ></img>
                </a>
                <div className="contact-info-panel-text">
                  Gertraudenstraße 18, 10178 Berlin
                </div>
              </div>
            </div>
            <div className="contact-appoinment-info">
              <p className="contact-appoinment-info-text">
                Um einen Termin zu vereinbaren, kontaktieren Sie uns einfach
                oder vereinbaren Sie einen Termin online.
              </p>
              <HashLink
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.etermin.net/praxis-retter"
                className="btn"
              >
                Termin online vereinbaren
              </HashLink>
              <HashLink
                target="_blank"
                rel="noopener noreferrer"
                to={praxisInfoPdf}
                className="btn"
              >
                Praxisinformationen drucken
              </HashLink>
            </div>
          </div>
          <div className="contact-form-wrapper">
            {ContactForm()}
            {AfterSubmitText()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
