import React from "react";
import { NavHashLink, HashLink } from "react-router-hash-link";
import officeTimes from "../data/officeTimes.json";

function Footer() {
  var _dayIndexArray = {
    0: "Mo",
    1: "Di",
    2: "Mi",
    3: "Do",
    4: "Fr",
    5: "Sa",
    6: "So",
  };

  const YEAR = new Date().getFullYear();

  return (
    <footer>
      <div className="primary-footer-wrapper">
        <div className="primary-footer-officeTimes" id="sprechzeiten">
          <div className="primary-footer-officeTimes-header">Sprechzeiten</div>
          {officeTimes.map((ot, i) => {
            if (i < 5) {
              return (
                <div className="primary-footer-officeTimes-item">
                  <p>{_dayIndexArray[i] + ": "}</p>
                  <p>{ot[_dayIndexArray[i]]}</p>
                </div>
              );
            }
            return <></>;
          })}
        </div>
        <div className="primary-footer-nav">
          <HashLink to="/impressum">Impressum</HashLink>
          <HashLink to="/datenschutz">Datenschutz</HashLink>
        </div>
        <div className="primary-footer-links">
          <p>{"© " + YEAR + " Praxis Retter "}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
