import React from "react";
import Hero from "../components/sites/Hero";
import Services from "../components/sites/Services";
import About from "../components/sites/About";
import Contact from "../components/sites/Contact";
import Team from "../components/sites/Team";

function MainPage() {
  return (
    <>
      <Hero />
      <Services />
      <About />
      <Team />
      <Contact />
    </>
  );
}

export default MainPage;
